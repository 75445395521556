import React from 'react';


const About = () => {


    return (

        <div className='w-full bg-white py-32  px-4'>
            <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
                <img className='w-[500px]  mx-auto rounded-full my-4' src={'https://firebasestorage.googleapis.com/v0/b/my-profile-923a6.appspot.com/o/Picsart_22-09-16_13-57-35-9861.jpg?alt=media&token=84ccbd61-d5e3-484e-aaf7-b27260d3e17e'} alt='/' />
                <div className='flex flex-col justify-center'>
                    {/* <p className='text-gray-500 font-bold '>DATA ANALYTICS DASHBOARD</p> */}
                    <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>About Me</h1>
                    <p>
                        I'm Web Developer, and I'm very passionate and dedicated to my work.<br />
                        Fresher Full Stack developer, <br />
                        I have acquired the skills and knowledge necessary<br />
                        to create highly usable and intuitive websites<br />
                        I enjoy every step of the developing process, from discussion to collaboration.
                    </p>
                    <a href='https://firebasestorage.googleapis.com/v0/b/my-profile-923a6.appspot.com/o/Yabez%20Resume.pdf?alt=media&token=1c43b390-3d41-4043-9cc7-39bc6df4016e'>
                        <button className='bg-gray-900  hover:bg-gray-700 text-[#ffffff] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Download CV</button>

                    </a>

                </div>
            </div>
        </div>

    );
};

export default About;
